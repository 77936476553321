var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-100 bg-white",style:(_vm.width >= 1000 ? 'margin-right:56px;' : '')},[_c('BContainer',{staticClass:"h-100 justify-content-center d-flex"},[_c('BRow',[_c('BCol',{staticClass:"text-left mx-auto",class:_vm.width > 510 ? 'col500' : '',attrs:{"cols":_vm.width <= 510 ? '12' : ''}},[_c('BRow',{staticClass:"text-center py-3 mt-4"},[_c('BCol',{staticClass:"pt-2 text-center",attrs:{"cols":"1"}},[_c('a',{staticClass:"pl-3",attrs:{"href":"/profile"}},[_c('BIcon',{attrs:{"icon":"chevron-left","scale":"1.2"}})],1)]),_c('BCol',[_c('h3',{staticClass:"text-center"},[_vm._v("Change Password")])]),_c('BCol',{attrs:{"cols":"1"}})],1),_c('BForm',{staticClass:"text-left",class:_vm.width > 510 ? 'p-3' : 'py-3',on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(!_vm.submitted)?_c('p',{staticClass:"pb-0 inputBlockLabel"},[_vm._v(" Password details ")]):_vm._e(),(!_vm.submitted)?_c('FormTextInput',{attrs:{"inputAttrs":{
              id: 'password',
              label: 'Current Password *',
              labelClass: 'text-primary d-flex',
              type: 'password',
              state: _vm.errorMessage.includes('current') ? false : null,
              autocomplete: 'current-password',
              errorMessage: 'Please enter your password',
              variant: 'secondary'
            },"id":"password"},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", $$v)},expression:"$v.form.password.$model"}}):_vm._e(),(!_vm.submitted)?_c('FormTextInput',{attrs:{"inputAttrs":{
              id: 'newPassword',
              label: 'New Password *',
              labelClass: 'text-primary d-flex',
              type: 'password',
              state: _vm.errorMessage.includes('new') ? false : null,
              errorMessage: 'Please enter your new password',
              variant: 'secondary'
            },"id":"newPassword"},model:{value:(_vm.$v.form.newPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.newPassword, "$model", $$v)},expression:"$v.form.newPassword.$model"}}):_vm._e(),(!_vm.submitted)?_c('FormTextInput',{attrs:{"inputAttrs":{
              id: 'confirmPassword',
              label: 'Confirm Password *',
              labelClass: 'text-primary d-flex',
              type: 'password',
              state: _vm.errorMessage.includes('confirm') ? false : null,
              errorMessage: 'Please enter your new password',
              variant: 'secondary'
            },"id":"confirmPassword"},model:{value:(_vm.$v.form.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.confirmPassword, "$model", $$v)},expression:"$v.form.confirmPassword.$model"}}):_vm._e(),_c('div',{staticClass:"justify-content-center d-flex"},[(!_vm.submitted)?_c('BButton',{staticClass:"text-white mt-3 px-5",attrs:{"type":"submit","variant":"secondary"}},[_vm._v("Submit")]):_vm._e()],1),(_vm.submitted && !_vm.unresolvableError)?_c('h1',{staticClass:"text-center"},[_c('BIcon',{attrs:{"icon":"check-circle","variant":"success","font-scale":"2"}})],1):_vm._e(),(_vm.submitted && !_vm.unresolvableError)?_c('p',{staticClass:"text-center"},[_vm._v(" Your password has successfully been updated! ")]):_vm._e(),(_vm.submitted && _vm.unresolvableError)?_c('h1',{staticClass:"text-center pt-3"},[_c('BIcon',{attrs:{"icon":"x-circle","variant":"danger","font-scale":"2"}})],1):_vm._e(),(_vm.submitted && _vm.unresolvableError)?_c('p',[_vm._v(" Password has not been changed, please contact Levno ")]):_vm._e(),_c('p',{staticClass:"text-white text-center pt-3"},[_c('a',{staticClass:"text-secondary",attrs:{"href":"/profile"}},[_vm._v("Back to profile")])]),(_vm.error)?_c('ErrorDisplay',{staticClass:"text-center text-danger",attrs:{"error":_vm.error}}):_vm._e(),(_vm.errorOccurred && !_vm.submitted)?_c('p',{staticClass:"my-3 text-center text-danger"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }