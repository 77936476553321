
import ErrorDisplay from '@/components/common/ErrorDisplay.vue';
import FormInput from '@/components/common/FormInput.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import ApiError from '@/services/api/models/ApiError';
import { trackEvent } from '@/services/Mixpanel';
import User from '@/store/modules/User';
import { Component, Vue } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

@Component({
  components: {
    FormInput,
    FormTextInput,
    ErrorDisplay
  },
  validations: {
    form: {
      password: {
        required
      },
      newPassword: {
        required
      },
      confirmPassword: {
        required
      }
    }
  },
  mixins: [validationMixin]
})
export default class ChangePassword extends Vue {
  public error: ApiError | null = null;
  public form = {
    password: '',
    newPassword: '',
    confirmPassword: ''
  };
  public attemptSubmit: boolean | null = null;
  public submitted = false;
  public unresolvableError = false;
  public errorMessage = 'Missing fields';
  public errorOccurred = false;
  public width = 1000;

  mounted() {
    trackEvent('User viewing change password');
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }
  public async onSubmit(): Promise<void> {
    this.errorOccurred = false;
    if (this.$v.form.$anyError || !this.validatePassword()) {
      this.attemptSubmit = false;
      this.errorOccurred = true;
      this.error = null;
      return;
    }
    this.error = null;
    try {
      await User.changePassword({
        email: User._currentUser?.email ?? '',
        password: this.form.password,
        newPassword: this.form.newPassword,
        passwordConfirm: this.form.confirmPassword,
        id: User._currentUser?.id ?? -1
      }).then(response => {
        if (response == -1) {
          this.unresolvableError = true;
        }
        this.submitted = true;
        trackEvent('User successfully changed their password');
      });
    } catch (error) {
      trackEvent('User encounted an error when changing their password');

      this.error = error as ApiError;
    }
  }

  public validatePassword(): boolean | null {
    if (this.form.password.length == 0) {
      this.errorMessage = 'Please enter your current password';
      return false;
    }
    if (this.form.newPassword.length == 0) {
      this.errorMessage = 'Please enter your new password';
      return false;
    }
    if (this.form.confirmPassword.length == 0) {
      this.errorMessage = 'Please confirm your password';
      return false;
    }
    if (this.form.newPassword.length < 8) {
      this.errorMessage = 'Password must be at least 8 characters long';
      return false;
    } else if (this.form.newPassword != this.form.confirmPassword) {
      this.errorMessage = 'Passwords must match';
      return false;
    }
    return true;
  }
}
